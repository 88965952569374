import {loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import JournalContent from "../../components/journal/JournalContent";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import React from "react";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";

const OhadarbJournal = ({}: any) => {

    const {t} = useTranslation()
    useEffect(() => {
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
    })

    return (
        <>
            <Helmet>
                <title>OHADA | {t('ohadarbJournal')}</title>
            </Helmet>
            <Layout title={'OHADARB | Journal'}>
                <JournalContent/>
            </Layout>
        </>
    )
}

export default OhadarbJournal;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
