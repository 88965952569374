import React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next";

const JournalHeader = ({setSelected, user}: any) => {

    const {t} = useTranslation()

    return (
        <>
            <section className="wrapper image-wrapper bg-image bg-overlay text-white"
                     data-image-src="/assets/img/affaire.png">
                <div className="container pt-19 pt-md-21 pb-18 pb-md-20 text-center">
                    <div className="row">
                        <div className="col-md-10 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
                            <h1 className="display-1 text-white mb-3">{t('ohadarbJournal')}</h1>
                            <p className="lead fs-lg px-md-3 px-lg-7 px-xl-8 px-xxl-2 ">
                                {t('HeaderInitText')}
                            </p>
                        </div>
                    </div>
                    {user?.id && user?.roles[0].name === 'ADMIN' &&
                        <div className="animate__animated animate__slideInUp animate__delay-3s">
                            <a href="#"
                               onClick={() => setSelected(null)}
                               rel="noopener noreferrer"
                               data-bs-target={`#modal-addJournal`}
                               data-bs-toggle="modal"
                               className="btn btn-lg btn-outline-white rounded">
                                <span className="">{t('Add Article')}</span>
                                <i className="uil uil-plus-circle"
                                   style={{fontSize: "25px", margin: "0 5px",}}></i>
                            </a>
                        </div>
                    }
                    {/*<div className="animate__animated animate__slideInUp animate__delay-3s">*/}
                    {/*    <a href="#"*/}
                    {/*       onClick={() => {*/}
                    {/*           window.location.replace("/about#douajni-gaston-kenfack")*/}
                    {/*       }}*/}
                    {/*       rel="noopener noreferrer"*/}
                    {/*       data-bs-target={`#modal-addJournal`}*/}
                    {/*       data-bs-toggle="modal"*/}
                    {/*       className="btn btn-lg btn-outline-white rounded">*/}
                    {/*        <span className="">{t('Add Article')}</span>*/}
                    {/*        <i className="uil uil-plus-circle"*/}
                    {/*           style={{fontSize: "25px", margin: "0 5px",}}></i>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
            </section>
        </>
    )
}

export default JournalHeader
